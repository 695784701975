a,
button,
input,
textarea {
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba($form-default-color, 0.65) !important;
  }
}

[data-focus-source='pointer'] > body *:focus {
  box-shadow: none !important;
}

[data-focus-source='pointer'] > body {
  input[type='checkbox'],
  input[type='radio'] {
    &:focus {
      box-shadow: inset 0 0 0 $border-width-form $form-default-color !important;
    }
  }
}

form,
.form {
  counter-reset: section;
}

.form-section {
  position: relative;
  margin: 2rem 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    @media (min-width: 768px) {
      counter-increment: section; /* Erhöht den Abschnittszähler */
      content: counter(section);
      position: absolute;
      top: 0;
      left: 0rem;
      width: 1.75rem;
      height: 1.75rem;
      color: find-color-contrast($secondary);
      text-align: center;
      line-height: 1.75rem;
      background-color: $secondary;
      border-radius: 999px;
    }
    @media (min-width: ($site-max-width + 48px)) {
      left: -2.5rem;
    }
  }

  h1,
  h2,
  h3,
  h4 {
    @media (min-width: 768px) {
      padding-left: 2.25rem;
    }
    @media (min-width: ($site-max-width + 48px)) {
      padding-left: unset;
    }
  }
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin: -1rem -0.375rem;

  .form-field {
    margin: 1.5rem 0.375rem;
  }

  + label {
    display: inline-block;
    margin-top: 0.5rem;
  }
}

.form-field {
  position: relative;
  width: 100%;
  min-width: 16rem;
  flex-basis: 51%;
  flex-grow: 1;
  flex-shrink: 0;
  margin: 1.5rem 0;
  text-align: left;
  @media (min-width: 768px) {
    flex-basis: 34%;
  }
  @media (min-width: 992px) {
    flex-basis: 26%;
  }
  &.is-ghost {
    height: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  label {
    overflow: visible;
  }

  .control {
    display: flex;
    align-content: stretch;
    align-items: center;
    justify-content: stretch;
    border-bottom: $border-width-form solid #3fa9f5;
    background-color: transparent;

    input,
    select,
    textarea {
      width: 100%;
      flex: 1 1 100%;
      padding: $form-padding;
      border: none;
      border-radius: $border-radius;
      outline: none;
      appearance: none;
      background: rgba($primary, 0.05);
      color: $contrast;
      overflow: hidden;
      margin-top: 0.5rem;
      color-scheme: dark;
    }
    textarea {
      line-height: $line-height-body;
    }
    select {
      height: 100%;
      cursor: pointer;
      &::-ms-expand {
        display: none;
      }
    }
    input[type='checkbox'],
    input[type='radio'] {
      padding: unset;
    }
    + .help,
    + .error {
      margin-top: 0.5rem;
      @each $name, $color in $state-colors {
        &.is-#{$name} {
          color: $color;
        }
      }
    }

    .icon {
      flex-shrink: 0;
      align-self: center;
      max-height: 1em;
      vertical-align: middle;
      padding: 0 map-get(split-spacing-to-directions($form-padding), 'right') 0
        map-get(split-spacing-to-directions($form-padding), 'left');
      &:first-child {
        padding: 0 0.125rem 0 map-get(split-spacing-to-directions($form-padding), 'left');
      }
      &:last-child {
        padding: 0 map-get(split-spacing-to-directions($form-padding), 'right') 0 0.125rem;
      }
    }

    .btn {
      flex-shrink: 0;
      border-radius: 0;
      margin: -$border-width-form 0;
      &:first-child {
        margin-left: -$border-width-form;
        border-radius: $border-radius 0 0 $border-radius;
      }
      &:last-child {
        margin-right: -$border-width-form;
        border-radius: 0 $border-radius $border-radius 0;
      }
    }
  }

  .checkbox-card,
  .radio-card {
    position: relative;
    height: 100%;
    padding: $form-padding;
    transition: 0.25s ease;
    cursor: pointer;
    overflow: hidden;
    &.checked {
      &.is-outlined {
        border-color: find-color-hover($form-default-color);
        box-shadow: inset 0 0 0 ($border-width-btn - $border-width-form) $form-default-color;
      }
      @each $name, $color in $theme-colors {
        &.is-#{$name} {
          color: find-color-contrast($color);
          background-color: $color;
          border-color: find-color-hover($color);
          &.is-outlined {
            color: $font-color-body;
            background-color: $contrast;
            border-color: find-color-hover($color);
            box-shadow: inset 0 0 0 ($border-width-btn - $border-width-form) $color;
          }
          @if $name == 'primary' {
            input[type='checkbox'],
            input[type='radio'] {
              &::after {
                background-color: find-color-hover($form-default-color);
              }
            }
            &.is-outlined {
              input[type='checkbox'],
              input[type='radio'] {
                &::after {
                  background-color: $form-default-color;
                }
                &:hover {
                  &::after {
                    background-color: find-color-hover($form-default-color);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .checkbox,
  .radio {
    display: flex;
    margin: 0.25rem 0;
    align-items: flex-start;
    cursor: pointer;
    &:hover {
      input[type='checkbox'],
      input[type='radio'] {
        background-color: find-color-hover($contrast);
        &:checked {
          &::after {
            background-color: find-color-hover($form-default-color);
          }
        }
      }
    }
  }

  input[type='checkbox'],
  input[type='radio'] {
    position: relative;
    flex: 0 0 1.25rem;
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.5rem;
    appearance: none;
    background: none;
    background-color: $contrast;
    border-radius: $border-radius;
    transition: background-color 0.25s ease;
    box-shadow: inset 0 0 0 1px $medium;
    overflow: hidden;
    cursor: pointer;
    &::after,
    &::before {
      content: '';
      position: absolute;
      transition: transform 0.075s ease;
      pointer-events: none;
      opacity: 0;
    }
    &::before {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transition: all 0.2s ease 0.15s;
      z-index: 2;
    }
    &:checked {
      &::before {
        transition: all 0.2s ease;
        opacity: 1;
      }
    }

    &::after {
      width: 150%;
      height: 150%;
      top: -25%;
      left: -25%;
      border-radius: 99px;
      background-color: $form-default-color;
      transform: scale(0);
      transition: all 0.2s ease;
    }
    &:checked {
      &::after {
        transform: scale(1);
        opacity: 1;
      }
    }
  }

  .checkbox {
    input[type='checkbox'] {
      border-radius: $border-radius;
      &::before {
        background: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTQgMTEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTkuOTQwMTg5MDEgMTYuNTk5MjAyOS0uMTIyMDY4NTgtLjAwMjU0ODQtLjA1MDIzMzM2LS4wMDUzOTE5LS4wNjQ4NzgyNy0uMDEwNzY1OS0uMDk0NTQ5NjItLjAyMzc2MzktLjExNjE5ODktLjA0MzM0NjEtLjA4MzY4OTg1LS4wNDIxNjA4LS4wNzY4MjE4LS4wNDgxMTcxLS4wNzM0NzgyOC0uMDU2MDM5My0uMDkxMDYzODQtLjA4NjYxODQtMy45LTQuMmMtLjM3NTgwMjc3LS40MDQ3MTA3LS4zNTIzNjgyOC0xLjAzNzQ0MTguMDUyMzQyMzktMS40MTMyNDQ2LjM3MzU3OTA4LS4zNDY4OTQ5Ljk0MTQ0NzgxLS4zNTM2MTEyIDEuMzIyMTgwNzUtLjAzNDI3NmwuMDkxMDYzODQuMDg2NjE4NCAzLjIwNjIwNjUxIDMuNDUzNDUxMSA3LjM2NzM1ODMtNy4wOTMzMjAyMWMuMzk3ODIxOS0uMzgzMDg3NzMgMS4wMzA4NzQyLS4zNzExNDMzNSAxLjQxMzk2MTkuMDI2Njc4NTIuMzUzNjE5NS4zNjcyMjAxOS4zNzA2NDM1LjkzNDg3MzQxLjA1ODI3MjUgMS4zMjEzNDA2NWwtLjA4NDk1MS4wOTI2MjEyNS04LjExMzE5MDYgNy44MTI0NzMyOWMtLjA0MzEwNTMuMDQwMDI2My0uMDg4Nzk3My4wNzU1MjM2LS4xMzY0OS4xMDY1MTM3LS4wNTI5NDM2LjAzNDQ0NjUtLjEwODcxNjEuMDYzNDU4Mi0uMTY2MTk5Mi4wODY4ODMybC0uMDgxMDkzMy4wMjkwMTk4LS4xMDg2Njc0LjAyNzA4MDd6IiBmaWxsPSIjZmZmIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNSAtNikiLz48L3N2Zz4=')
          transparent no-repeat center center / 50% auto;
        transform: rotate(45deg);
      }
      &:checked {
        &::before {
          transform: rotate(0);
        }
      }
    }
  }

  .radio {
    input[type='radio'] {
      border-radius: 99px;
      &::before {
        border-radius: 99px;
        background-color: find-color-contrast($form-default-color);
        transform: scale(0);
      }
      &:checked {
        &::before {
          transform: scale(0.45);
        }
      }
    }
  }

  .help,
  .error-message,
  .warning-message,
  .success-message {
    font-size: 0.875rem;
    margin: 0.25rem 0 0;
    line-height: 1;
  }

  .error-message,
  .warning-message,
  .success-message {
    display: none;
  }

  .error-message {
    color: $danger;
  }

  .warning-message {
    color: $warning;
  }

  .success-message {
    color: $success;
  }

  &.is-error {
    .control {
      border-color: $danger;
    }
    .error-message {
      display: block;
    }
  }

  &.is-warning {
    .control {
      border-color: $warning;
    }

    .warning-message {
      display: block;
    }
  }

  &.is-success {
    .control {
      border-color: $success;
    }

    .success-message {
      display: block;
    }
  }
}

// Honepot field
form {
  &.is-submitting {
    pointer-events: none;
  }
  input[name='website_name'] {
    position: absolute;
    left: -99999px;
    top: -99999px;
    opacity: 0;
  }
}

// Form errors
.notification {
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: $border-radius;
  color: find-color-contrast($white);
  background-color: $white;
  line-height: 1.3;
  &:empty {
    padding: 0;
  }
  h4 {
    margin-bottom: 0;
  }
  p {
    margin-top: 0.3em;
  }
  @each $name, $color in $state-colors {
    &.is-#{$name} {
      background-color: $color;
      color: find-color-contrast($color);
    }
  }
}

/* --- Browser support --- */

@media all and (-ms-high-contrast: none) {
  .checkbox-card,
  .radio-card {
    &.checked {
      background-color: $contrast !important;
      color: $font-color-body !important;
    }
  }
}

.toggle {
  input[type='checkbox']:not(:checked):indeterminate::before {
    left: 13px;
  }

  input[type='checkbox']:checked {
    background: red !important;
    &::before {
      left: 22px;
      background-color: $white;
    }
  }

  label {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
  }

  input[type='checkbox'] {
    position: relative;
    z-index: 3;
    flex: 0 0 42px;
    width: 42px;
    height: 24px;
    padding: 0;
    margin: 0 8px 0 0;
    overflow: hidden;
    cursor: pointer;
    background: none;
    background-color: $grey-light;
    border-radius: $border-radius;
    transition: 0.25s ease;
    transition-property: background-color;
    appearance: none;
    box-shadow: none;

    &:last-child {
      margin-right: 0;
    }

    &::before {
      position: absolute;
      top: 4px;
      left: 4px;
      z-index: 2;
      width: 16px;
      height: 16px;
      pointer-events: none;
      content: '';
      background-color: $primary;
      border-radius: $border-radius;
      transition: all 0.2s ease;
      opacity: 1;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: $grey-light !important;

      &::before {
        background-color: $grey !important;
      }
    }
  }
}
