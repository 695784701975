/* ----- Colors ----- */
$primary: #3fa9f5;
$blue-dark: #7aa3cf;

$grey: #555555;
$grey-dark: #1e2e42;
$grey-light: #cecece;
$grey-lighter: #fafafa;

$base-gradient: linear-gradient(62deg, #05478a, #061c33);

/* Raw colors */
$orange: #ff6600;
$blue: #1d263a;
$blue-light: #40aaf5;
$red: #f53034;
$yellow: #ffb040;
$green: #20cd83;

/* 5 shades of grey */
$white: #fff;
$off-white: #f0f0f0;
$medium: #bbb;
$black: #000;
$not-black: #061c33;

$primary: $primary;
$secondary: $blue;
$contrast: $white;
$disabled: $medium;
$success: $green;
$warning: $yellow;
$danger: $red;
$info: $blue-light;

/* Theme colors */
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'contrast': $contrast,
  'danger': $danger,
);

/* State colors */
$state-colors: (
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'error': $danger,
);

/* ----- Forms/Buttons ----- */

/* Color */
$form-default-color: $primary;

/* Border */
$border-radius: 0.25rem; // has to be rem
$border-width-btn: 0.125rem; // has to be rem
$border-width-form: 0.0625rem; // has to be rem

/* Spacing */
$btn-padding: 11px 1.25rem 13px;
$form-padding: 0.75rem 0.5rem;

/* ----- Navbar ----- */

$navbar-height: 6.25rem;
$navbar-height-mobile: 5rem;
$navbar-height-shrinked: 4.5rem;
$navbar-background: $base-gradient;
$navbar-font-color: $contrast;
$navbar-breakpoint: 768px;
$navbar-shrink-transition: 0.25s ease;
$navbar-logo-max-height: 2.75rem;

/* ----- Font styles ----- */

/* Font basics */
$font-family: 'Source Sans Pro', sans-serif;
$font-color-body: $contrast;

/* Font weights */
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;

/* Font size */
$font-size-body: 1rem;
$font-size-h1: 3rem;
$font-size-h2: 2.5rem;
$font-size-h3: 2rem;
$font-size-h4: 1.125rem;
$font-size-h5: 1rem;
$font-size-caption: 1.125rem;

/* Font size mobile */
$font-size-h1-mobile: 2rem;
$font-size-h2-mobile: 1.75rem;
$font-size-h3-mobile: 1.3rem;
$font-size-h4-mobile: 1.125rem;
$font-size-h5-mobile: 1rem;
$font-size-caption-mobile: 1.125rem;

/* Line height */
$line-height-body: 1.65;
$line-height-heading: 1.15;

/* Letter-spacing */
$letter-spacing-body: 0;
$letter-spacing-headings: 0;

/* ----- Spacing ----- */
$site-max-width: 1200px;
$spacer: 1rem;
$section-padding: ($spacer * 4);
