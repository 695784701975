html {
  font-size: 16px;
  @media (max-width: 767px) {
    font-size: 15px;
  }
}

body {
  font-family: $font-family;
  font-weight: $font-weight-regular;
  font-size: $font-size-body;
  color: $white;
  line-height: $line-height-body;
  letter-spacing: $letter-spacing-body;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0.63em 0;
  line-height: $line-height-heading;
  letter-spacing: $letter-spacing-headings;
  &:first-child {
    margin-top: 0;
  }
}

p:last-child {
  margin-bottom: 0;
}

a {
  color: $primary;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

b {
  font-weight: $font-weight-semibold;
}

h1 {
  font-size: $font-size-h1-mobile;
  font-weight: $font-weight-light;

  @media (min-width: 768px) {
    font-size: $font-size-h1;
  }

  b {
    display: block;
    color: $primary;
  }
}

h2 {
  font-size: 2.25rem;
  font-weight: $font-weight-light;
}

h3 {
  font-size: $font-size-h3-mobile;
  font-weight: $font-weight-light;

  @media (min-width: 768px) {
    font-size: $font-size-h3;
  }
}

h4 {
  font-size: $font-size-h4-mobile;
  font-weight: $font-weight-light;

  @media (min-width: 768px) {
    font-size: $font-size-h4;
  }
}

h5 {
  font-size: $font-size-h5-mobile;
  font-weight: $font-weight-semibold;

  @media (min-width: 768px) {
    font-size: $font-size-h5;
  }
}
