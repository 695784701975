table {
  border-collapse: collapse;

  @media print {
    color: $not-black;
  }

  td,
  th {
    padding: 0.875rem 0;
    vertical-align: top;
    border-bottom: 1px solid rgba($grey-light, 0.6);
    text-align: left;
  }
  th {
    font-weight: bold;
    padding: 0.5rem 0;

    @media print {
      border-color: $grey-dark;
    }
  }
  td {
    line-height: 1.33;
    font-size: 0.875rem;
    border-color: rgba($grey-light, 0.25);

    @media print {
      border-color: $medium;
    }
  }
  td a {
    word-break: break-word;
  }
  td p:first-child {
    margin-top: 0;
  }
  td em {
    /* Make emphasized text bold and non-italic */
    font-weight: bold;
    font-style: normal;
  }
  tr:last-of-type > td {
    border-bottom: 0;
  }
}
