* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  background-color: #061e36;
  scroll-behavior: smooth;
  scroll-padding-top: $navbar-height-mobile;
  @media (min-width: 768px) {
    scroll-padding-top: $navbar-height;
  }
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: $base-gradient;

  @media print {
    background: transparent;
  }

  &.is-clipped {
    overflow: hidden;
  }
}

footer {
  margin-top: auto;
}

img {
  max-width: 100%;
}

hr {
  border: none;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.2);
}

@mixin wrapper {
  max-width: $site-max-width;
  margin: 0 auto;
  padding: 0 $spacer;
  @media (min-width: 768px) {
    padding: 0 ($spacer * 2);
  }
}
.wrapper {
  @include wrapper;
}

.is-hidden {
  display: none;
}

.is-desktop-only {
  @media (max-width: 767.98px) {
    display: none !important;
  }
}

.is-mobile-only {
  @media (min-width: 768px) {
    display: none !important;
  }
}

// Custom scrollbar
::-webkit-scrollbar {
  width: 0.75rem;
  background-color: transparent;
}
::-webkit-scrollbar-track {
  border-radius: $border-radius;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  width: 0.375rem;
  border: 0.1875rem solid rgba(black, 0);
  border-radius: 10px;
  background-color: rgba($blue-dark, 0.5);
  transition: background-color 0.25s ease;
  transition-property: background-color;
  background-clip: padding-box;
  &:hover {
    background-color: rgba($blue-dark, 1);
  }
}
