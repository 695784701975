.btn {
  display: inline-block;
  position: relative;
  padding: $btn-padding;
  min-width: 9rem;
  color: $primary;
  line-height: 1;
  text-align: center;
  font-weight: 500;
  border: none;
  outline: none;
  border-radius: $border-radius;
  background-color: transparent;
  transition: 0.25s ease;
  appearance: none;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    color: darken($primary, 15%);
  }

  &.is-outlined {
    background-color: transparent;
    border: $border-width-btn solid transparent;
    padding: (0.75rem - $border-width-btn) (1rem - $border-width-btn);
  }

  &.is-loading {
    color: transparent !important;
    cursor: progress;
    &:active {
      pointer-events: none;
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      height: 1em;
      width: 1em;
      top: 50%;
      left: 50%;
      border: 3px solid white;
      border-radius: 999px;
      border-top-color: transparent !important;
      transform: translate(-50%, -50%) rotate(0deg);
      animation: spin 0.9s linear infinite;
      z-index: 2;
    }
  }

  @keyframes spin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }

  @each $name, $color in $theme-colors {
    &.is-#{$name} {
      background-color: $color;
      color: find-color-contrast($color);
      &:hover {
        background-color: find-color-hover($color);
      }

      &.is-outlined {
        background-color: transparent;
        color: $color;
        border-color: $color;
        &:hover {
          color: find-color-contrast($color);
          border-color: find-color-hover($color);
          background-color: find-color-hover($color);
        }
      }

      &.is-loading {
        &:hover {
          background-color: $color;
        }
        &::after {
          border-color: find-color-contrast($color);
        }
      }
    }
  }

  &:disabled,
  &.is-disabled {
    opacity: 0.4;
    background-color: $disabled !important;
    color: find-color-contrast($disabled);
    cursor: not-allowed !important;
    &:hover {
      background-color: $disabled !important;
    }
    &:active {
      pointer-events: none !important;
    }
  }

  &.is-outlined {
    &:disabled,
    &.is-disabled {
      background-color: transparent !important;
      color: $disabled !important;
      border-color: $disabled !important;
    }
  }

  .icon,
  .btn-icon {
    max-height: 1em;
    margin: -1em 0.75rem -0.875em 0;
    vertical-align: middle;
    &:only-child {
      margin-right: 0;
    }
  }

  &.is-primary {
    box-shadow: 0 4px 8px 0 rgba(6, 28, 51, 0.13);
    &:hover {
      box-shadow: 0 10px 14px 0 rgba(6, 28, 51, 0.13);
    }
  }
}

.badge {
  display: inline-block;
  padding: 4px 7px;
  min-width: 22px;
  text-align: center;
  margin-right: 6px;
  font-size: 14px;
  font-weight: 600;
  background-color: $primary;
  border-radius: 999px;
  color: $contrast;
  line-height: 1;
  letter-spacing: 0.03em;
}
